import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
var getSelectedAtributes = function getSelectedAtributes(attributes) {
  var mandatory = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return attributes == null ? void 0 : attributes.reduce(function (acc, attribute) {
    return _extends({}, acc, _defineProperty({}, attribute.type, !mandatory.length || !mandatory.includes(attribute.type) ? attribute.current : ''));
  }, {});
};

var getAttributes = function getAttributes(attributes, selectedAttributes) {
  return attributes == null ? void 0 : attributes.map(function (attribute) {
    return _extends({}, attribute, {
      current: selectedAttributes[attribute.type],
      selected: !!selectedAttributes[attribute.type]
    });
  });
};

var getParentAttribute = function getParentAttribute(type, attributes) {
  var index = attributes == null ? void 0 : attributes.findIndex(function (attribute) {
    return attribute.type === type;
  });
  return attributes[index - 1];
};

var getParentAttributeSkus = function getParentAttributeSkus(_ref, variations) {
  var type = _ref.type,
    current = _ref.current;
  return variations == null ? void 0 : variations.reduce(function (acc, variation) {
    if (variation.type === type && variation.value === current) {
      acc.push(variation.id);
    }
    return acc;
  }, []);
};

var getVariations = function getVariations(variations, attributes) {
  return variations == null ? void 0 : variations.filter(function (variation) {
    var parentAttribute = getParentAttribute(variation.type, attributes);
    if (!parentAttribute) return true;
    if (!parentAttribute.selected) return false;
    var skus = getParentAttributeSkus(parentAttribute, variations);
    return skus.includes(variation.id);
  });
};

var getVariationsById = function getVariationsById(variations) {
  return variations == null ? void 0 : variations.reduce(function (acc, variation) {
    var _extends3;
    if (!variation.available) return acc;
    return _extends({}, acc, _defineProperty({}, variation.id, _extends({}, acc[variation.id] || {}, (_extends3 = {}, _defineProperty(_extends3, variation.type, variation.value), _defineProperty(_extends3, "path", variation.path), _extends3))));
  }, {});
};

var getScore = function getScore(selectedAttributes, variation, attributes) {
  return Object.keys(selectedAttributes).reduce(function (acc, key) {
    if (selectedAttributes[key] === variation[key]) {
      var index = attributes.findIndex(function (_ref2) {
        var type = _ref2.type;
        return type === key;
      });
      var indexScore = index > -1 ? attributes.length - index : 0;
      return acc + 1 + indexScore / 100;
    }
    return acc;
  }, 0);
};

var getMatchedVariation = function getMatchedVariation(selected, variations, attributes) {
  var defaultSelected = getSelectedAtributes(attributes);
  var updatedAttributes = _extends({}, defaultSelected, _defineProperty({}, selected.type, selected.value));
  var variationsById = getVariationsById(variations);
  return Object.entries(variationsById).reduce(function (acc, _ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      sku = _ref4[0],
      variation = _ref4[1];
    if (variation[selected.type] === selected.value) {
      return [].concat(_toConsumableArray(acc), [_extends({}, variation, {
        id: sku,
        score: getScore(updatedAttributes, variation, attributes)
      })]);
    }
    return acc;
  }, []).sort(function (variationA, variationB) {
    return variationB.score - variationA.score;
  }).shift();
};
export { getAttributes, getMatchedVariation, getParentAttribute, getParentAttributeSkus, getScore, getSelectedAtributes, getVariations, getVariationsById };