import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { string, shape } from 'prop-types';
import StereoActionCard from '@magalu/stereo-ui/organisms/ActionCard';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var ActionCard = function ActionCard(_ref) {
  var staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleClick = function handleClick() {
    publish('action:click', staticProps);
  };
  return React.createElement(StereoActionCard, _extends({
    onButtonClick: handleClick
  }, staticProps));
};
process.env.NODE_ENV !== "production" ? ActionCard.propTypes = {
  "static": shape({
    alt: string,
    data: shape({
      action: string,
      text: string,
      title: string
    }),
    href: string,
    image: string,
    type: string
  }),
  structure: shape({})
} : void 0;
ActionCard.defaultProps = {
  "static": {},
  structure: {}
};
ActionCard.ssr = false;
export default withLayoutProps(ActionCard);