import React from 'react';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { bool, string } from 'prop-types';
import { Skeleton } from '../Skeleton';
var AdsBannerSkeleton = function AdsBannerSkeleton(_ref) {
  var bg = _ref.bg,
    height = _ref.height,
    isVisible = _ref.isVisible,
    width = _ref.width;
  return React.createElement(React.Fragment, null, isVisible && React.createElement(Flex, {
    bg: bg,
    "data-testid": "ads-banner-skeleton",
    justifyContent: "center",
    width: "100%"
  }, React.createElement(Skeleton, {
    height: height,
    width: width,
    config: {
      body: [[{
        height: height,
        radius: 2,
        width: width,
        x: 0,
        y: 0
      }]],
      color: '#E8E8E8'
    }
  })));
};
AdsBannerSkeleton.defaultProps = {
  bg: 'transparent'
};
process.env.NODE_ENV !== "production" ? AdsBannerSkeleton.propTypes = {
  bg: string,
  height: string.isRequired,
  isVisible: bool.isRequired,
  width: string.isRequired
} : void 0;
export default AdsBannerSkeleton;