import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var _excluded = ["message", "intent", "linkText"];
import React from 'react';
import { array, oneOf, oneOfType, shape, string } from 'prop-types';
import Alert from '@magalu/stereo-ui/molecules/AlertText';
import Box from '@magalu/stereo-ui/atoms/Box';
import Link from '@magalu/stereo-ui/atoms/Link';
import { usePublisher } from '@magalu/mixer-publisher';
function getPublishMessage(messages, publish, linkText) {
  if (Array.isArray(messages)) {
    return messages.map(function (message) {
      if (message.includes('publish: ')) {
        var _message$split = message.split('publish: '),
          _message$split2 = _slicedToArray(_message$split, 2),
          publishMsg = _message$split2[1];
        return React.createElement(Link, {
          as: "span",
          color: "inherit",
          "data-testid": "link-knowmore",
          onClick: function onClick() {
            return publish(publishMsg);
          }
        }, linkText);
      }
      return message;
    });
  }
  return messages;
}
var AlertText = function AlertText(_ref) {
  var _ref$static = _ref["static"],
    message = _ref$static.message,
    intent = _ref$static.intent,
    linkText = _ref$static.linkText,
    layout = _objectWithoutProperties(_ref$static, _excluded);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var alerts = typeof message === 'string' ? [message] : message;
  return alerts && alerts.map(function (value) {
    var values = getPublishMessage(value, publish, linkText);
    return React.createElement(Box, _extends({
      key: "alert-".concat(value)
    }, layout, {
      "data-testid": "alert"
    }), React.createElement(Alert, {
      message: values,
      intent: intent
    }));
  });
};
AlertText.ssr = false;
process.env.NODE_ENV !== "production" ? AlertText.propTypes = {
  "static": shape({
    intent: oneOf(['info', 'success', 'warning', 'danger']),
    linkText: string,
    message: oneOfType([string, array])
  })
} : void 0;
AlertText.defaultProps = {
  "static": {
    linkText: 'Saiba mais'
  }
};
export default AlertText;