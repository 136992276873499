import React from 'react';
import { shape, string } from 'prop-types';
import Text from '@magalu/stereo-ui/atoms/Text';
import withLayoutProps from '../../../hocs/withLayoutProps';
export var AccountTitle = function AccountTitle(_ref) {
  var staticProps = _ref["static"];
  return React.createElement(Text, {
    color: "text.base"
  }, React.createElement("span", null, "Ol\xE1"), React.createElement("strong", null, " ".concat(staticProps.accountName || 'Cliente', ". ")), React.createElement("span", null, staticProps.accountInfo));
};
process.env.NODE_ENV !== "production" ? AccountTitle.propTypes = {
  "static": shape({
    accountInfo: string,
    accountName: string
  }).isRequired
} : void 0;
AccountTitle.ssr = true;
export default withLayoutProps(AccountTitle);