import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import { bool, shape, oneOfType, string, number } from 'prop-types';
import { Link, Box, Image } from '@magalu/stereo-ui/atoms';
import { useInView } from 'react-intersection-observer';
import { useAdsEvents } from '@magalu/mixer-graphql';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useAdsProvider } from '../../../contexts';
import { usePublisher } from '@magalu/mixer-publisher';
import AdsBannerSkeleton from '../AdsBannerSkeleton';
import { appendBannerToSlot, buildEventData, buildAdsBannerEventData, getCustomerId } from './AdsBanner.utils';
var AdsBanner = function AdsBanner(_ref) {
  var data = _ref.data,
    _ref$static = _ref["static"],
    adsPath = _ref$static.adsPath,
    _ref$static$bg = _ref$static.bg,
    bg = _ref$static$bg === void 0 ? 'transparent' : _ref$static$bg,
    _ref$static$borderRad = _ref$static.borderRadius,
    borderRadius = _ref$static$borderRad === void 0 ? '8px' : _ref$static$borderRad,
    campaignId = _ref$static.campaignId,
    staticCustomerId = _ref$static.customerId,
    _ref$static$hasPlaceh = _ref$static.hasPlaceholder,
    hasPlaceholder = _ref$static$hasPlaceh === void 0 ? false : _ref$static$hasPlaceh,
    height = _ref$static.height,
    imageUrl = _ref$static.imageUrl,
    index = _ref$static.index,
    _ref$static$isBannerC = _ref$static.isBannerCarousel,
    isBannerCarousel = _ref$static$isBannerC === void 0 ? false : _ref$static$isBannerC,
    position = _ref$static.position,
    redirectUrl = _ref$static.redirectUrl,
    screenName = _ref$static.screenName,
    slot = _ref$static.slot,
    trackId = _ref$static.trackId,
    width = _ref$static.width,
    structure = _ref.structure;
  var customerId = getCustomerId(staticCustomerId);
  var bannerProps = {
    campaignId: campaignId,
    height: height,
    imageUrl: imageUrl,
    redirectUrl: redirectUrl,
    trackId: trackId
  };
  var useInViewConfig = {
    threshold: 0.5,
    triggerOnce: true
  };
  var useAdsEventsData = {
    adsPath: adsPath,
    customerId: customerId,
    data: data,
    structure: structure
  };
  var _useState = useState(imageUrl ? bannerProps : null),
    _useState2 = _slicedToArray(_useState, 2),
    banner = _useState2[0],
    setBanner = _useState2[1];
  var _useInView = useInView(useInViewConfig),
    _useInView2 = _slicedToArray(_useInView, 2),
    ref = _useInView2[0],
    inView = _useInView2[1];
  var _useAdsEvents = useAdsEvents(useAdsEventsData),
    sendAdsClickEvents = _useAdsEvents.sendAdsClickEvents,
    sendAdsImpressionEvents = _useAdsEvents.sendAdsImpressionEvents;
  var _useAdsProvider = useAdsProvider(),
    ads = _useAdsProvider.ads,
    getBannerBySlot = _useAdsProvider.getBannerBySlot,
    isBannerLoading = _useAdsProvider.isLoading;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleViewPromotion = function handleViewPromotion() {
    sendAdsImpressionEvents(buildAdsBannerEventData(banner, index));
    publish('promotion:view', buildEventData(banner, isBannerCarousel, screenName, structure, {
      index: index,
      position: position,
      slot: slot
    }));
  };
  var handleClick = function handleClick() {
    if (banner) {
      sendAdsClickEvents(buildAdsBannerEventData(banner, index));
      publish('promotion:click', buildEventData(banner, isBannerCarousel, screenName, structure, {
        index: index,
        position: position,
        slot: slot
      }));
    }
  };
  useEffect(function () {
    if (!isBannerCarousel) {
      appendBannerToSlot({
        adsData: ads,
        getBannerBySlot: getBannerBySlot,
        setBanner: setBanner,
        slotName: slot
      });
    }
  }, [ads, slot, getBannerBySlot]);
  useEffect(function () {
    if (inView && banner) {
      handleViewPromotion();
    }
  }, [inView, banner, slot]);
  return React.createElement(React.Fragment, null, banner && !isBannerLoading && React.createElement(Box, {
    ref: ref,
    bg: {
      bg: bg
    }
  }, React.createElement(Link, {
    "data-testid": "ads-banner",
    href: banner.redirectUrl,
    style: {
      display: 'block'
    },
    key: banner.trackId,
    onClick: handleClick
  }, React.createElement(Image, {
    src: banner.imageUrl,
    style: {
      borderRadius: borderRadius,
      height: height
    },
    key: banner.trackId
  }))), React.createElement(AdsBannerSkeleton, {
    bg: bg,
    height: height,
    isVisible: isBannerLoading && hasPlaceholder,
    width: width
  }));
};
AdsBanner.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? AdsBanner.propTypes = {
  data: shape({}),
  "static": oneOfType([shape({
    isBannerCarousel: bool,
    position: string,
    slot: string
  }), shape({
    campaignId: string,
    customerId: string,
    hasPlaceholder: bool,
    height: string,
    imageUrl: string,
    index: number,
    position: string,
    redirectUrl: string,
    slot: string,
    trackId: string,
    width: string
  })]),
  structure: shape({
    cookies: shape({
      accountData: shape({
        customerId: string
      })
    })
  })
} : void 0;
AdsBanner.ssr = true;
export default withLayoutProps(AdsBanner);