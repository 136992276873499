import _extends from "@babel/runtime/helpers/esm/extends";
import { getCookie, cookiesConstants } from '@magalu/mixer-utils';
var buildEventData = function buildEventData(banner, isBannerCarousel, screenName, structure) {
  var additionalData = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  return _extends({}, banner, {
    contentType: isBannerCarousel ? 'banner_list' : 'banner',
    page: structure == null ? void 0 : structure.name,
    screenName: screenName || (structure == null ? void 0 : structure.name)
  }, additionalData);
};
var buildAdsBannerEventData = function buildAdsBannerEventData(banner, index) {
  return [{
    position: index !== null && index !== void 0 ? index : 0,
    trackId: banner.trackId
  }];
};
var appendBannerToSlot = function appendBannerToSlot(_ref) {
  var adsData = _ref.adsData,
    getBannerBySlot = _ref.getBannerBySlot,
    setBanner = _ref.setBanner,
    slotName = _ref.slotName;
  if (slotName && adsData && Object.keys(adsData.banners).length) {
    var bannersInSlot = getBannerBySlot(slotName);
    if (bannersInSlot == null ? void 0 : bannersInSlot.length) {
      setBanner(bannersInSlot[0]);
    }
  }
};
var getCustomerId = function getCustomerId(staticCustomerId) {
  var CUSTOMER_ID = cookiesConstants.CUSTOMER_ID;
  return staticCustomerId || getCookie(CUSTOMER_ID);
};
export { appendBannerToSlot, buildAdsBannerEventData, buildEventData, getCustomerId };