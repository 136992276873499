import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import StereoAttributeList from '@magalu/stereo-ui/organisms/AttributeList';
import { routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import { PRODUCT_ATTRIBUTE_LIST_SHOW_WARNING_VOLTAGE_TEXT } from '../../../commons';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { getAttributes, getSelectedAtributes, getMatchedVariation, getVariations } from './AttributeList.utils';
var AttributeList = function AttributeList(_ref) {
  var _ref$static = _ref["static"],
    _ref$static$mandatory = _ref$static.mandatorySelectionTypes,
    mandatorySelectionTypes = _ref$static$mandatory === void 0 ? [] : _ref$static$mandatory,
    _ref$static$alignment = _ref$static.alignment,
    alignment = _ref$static$alignment === void 0 ? 'center' : _ref$static$alignment,
    _ref$static$colorMinL = _ref$static.colorMinLength,
    colorMinLength = _ref$static$colorMinL === void 0 ? 10 : _ref$static$colorMinL,
    _ref$static$colorVari = _ref$static.colorVariant,
    colorVariant = _ref$static$colorVari === void 0 ? 'smooth' : _ref$static$colorVari,
    product = _ref.data.product,
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish,
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var productId = structure.route.productId,
    _structure$basePath = structure.basePath,
    basePath = _structure$basePath === void 0 ? '' : _structure$basePath;
  var attributes = product.attributes,
    variations = product.variations,
    title = product.title;
  var _useState = useState(getSelectedAtributes(attributes, mandatorySelectionTypes)),
    _useState2 = _slicedToArray(_useState, 2),
    selectedAttributes = _useState2[0],
    setSelectedAttributes = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    showWarningText = _useState4[0],
    setShowWarningText = _useState4[1];
  var _useState5 = useState(''),
    _useState6 = _slicedToArray(_useState5, 2),
    iconWarningText = _useState6[0],
    setIconWarningText = _useState6[1];
  var _useState7 = useState(''),
    _useState8 = _slicedToArray(_useState7, 2),
    warningText = _useState8[0],
    setWarningText = _useState8[1];
  var attributesList = getAttributes(attributes, selectedAttributes);
  var variationsList = getVariations(variations, attributesList);
  var handlePublishAttributeChange = function handlePublishAttributeChange() {
    publish('product:attribute:change', attributesList);
  };
  var handlePublishVoltageText = function handlePublishVoltageText(externalInfo) {
    var showComponent = externalInfo.showComponent,
      message = externalInfo.message,
      icon = externalInfo.icon;
    setShowWarningText(showComponent);
    setWarningText(message);
    setIconWarningText(icon);
  };
  useEffect(function () {
    subscribe('product:get:attribute', handlePublishAttributeChange);
    handlePublishAttributeChange();
    return function () {
      unsubscribe('product:get:attribute', handlePublishAttributeChange);
    };
  }, [attributesList]);
  useEffect(function () {
    subscribe(PRODUCT_ATTRIBUTE_LIST_SHOW_WARNING_VOLTAGE_TEXT, handlePublishVoltageText);
    return function () {
      unsubscribe(PRODUCT_ATTRIBUTE_LIST_SHOW_WARNING_VOLTAGE_TEXT, handlePublishVoltageText);
    };
  }, []);
  useDidUpdateEffect(function () {
    setSelectedAttributes(getSelectedAtributes(attributes, mandatorySelectionTypes));
  }, [JSON.stringify(variations)]);
  var handleAttributeChanged = function handleAttributeChanged(_e, selected) {
    var matchedVariation = getMatchedVariation(selected, variations, attributes);
    var updateSelected = Object.entries(selectedAttributes).reduce(function (acc, _ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        type = _ref3[0],
        value = _ref3[1];
      if (type === selected.type) {
        acc[selected.type] = selected.value;
      } else if (mandatorySelectionTypes.length && mandatorySelectionTypes.includes(type)) {
        acc[type] = matchedVariation[type] === value ? value : '';
      }
      return acc;
    }, _extends({}, selectedAttributes));
    var _attributes$find = attributes.find(function (attribute) {
        return attribute.type === selected.type;
      }),
      label = _attributes$find.label;
    publish('product:attribute:click', {
      type: label,
      value: selected.value
    });
    setSelectedAttributes(updateSelected);
    setShowWarningText(false);
    if (matchedVariation.id !== productId) {
      var path = "".concat(basePath, "/").concat(matchedVariation.path);
      routePush({
        path: path,
        spa: true
      });
    }
  };
  return React.createElement(StereoAttributeList, {
    fullWidth: true,
    title: title,
    alignment: alignment,
    attributes: attributesList,
    variations: variationsList,
    onAttributeChange: handleAttributeChanged,
    colorMinLength: colorMinLength,
    colorVariant: colorVariant,
    showWarningText: showWarningText,
    warningMessage: warningText,
    iconWarningText: iconWarningText
  });
};
process.env.NODE_ENV !== "production" ? AttributeList.propTypes = {
  data: shape({
    product: shape({
      attributes: arrayOf(shape({
        current: string,
        selected: bool,
        type: string,
        values: arrayOf(string)
      })),
      variations: arrayOf(shape({
        current: string,
        type: string,
        values: arrayOf(string)
      }))
    })
  }).isRequired,
  "static": shape({
    alignment: oneOf(['start', 'end', 'center']),
    colorMinLength: number,
    colorVariant: oneOf(['smooth', 'button']),
    mandatorySelectionTypes: arrayOf(string)
  }).isRequired,
  structure: shape({
    asPath: string,
    basePath: string
  }).isRequired
} : void 0;
AttributeList.ssr = true;
AttributeList.displayName = 'AttributeList';
AttributeList.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
export default withLayoutProps(AttributeList);