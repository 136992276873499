import _extends from "@babel/runtime/helpers/esm/extends";
import { parsePath } from '@magalu/mixer-utils';
export var parseAdsProducts = function parseAdsProducts(adsResponse) {
  return adsResponse == null ? void 0 : adsResponse.map(function (product, index) {
    var _product$price, _product$shippingTag, _product$shippingTag2, _product$shippingTag3, _product$shippingTag4;
    if (!Object.values(product).length || !product.title) return null;
    return _extends({}, product, {
      available: true,
      brand: product.brand,
      campaignId: product.campaignId,
      category: {
        name: product.category
      },
      id: product.id,
      image: product.image,
      offerTags: product.offerTags,
      path: '/',
      position: index + 1,
      price: _extends({}, product.price, {
        paymentMethodDescription: product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.paymentMethodDescription
      }),
      rating: product.rating,
      seller: {
        id: product.seller.id
      },
      shippingTag: {
        complement: (_product$shippingTag = product.shippingTag) == null ? void 0 : _product$shippingTag.complement,
        cost: (_product$shippingTag2 = product.shippingTag) == null ? void 0 : _product$shippingTag2.cost,
        source: (_product$shippingTag3 = product.shippingTag) == null ? void 0 : _product$shippingTag3.source,
        time: (_product$shippingTag4 = product.shippingTag) == null ? void 0 : _product$shippingTag4.time
      },
      subcategory: {
        name: product.subcategory.name
      },
      title: product.title,
      trackId: product.trackId,
      url: parsePath(product == null ? void 0 : product.path)
    });
  }).filter(Boolean);
};