import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var _excluded = ["static", "structure", "onClick", "slotOnload"];
import React, { useEffect, useState } from 'react';
import { string, arrayOf, number, oneOfType, bool, func, shape } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Box } from './AdUnit.styles';
var transformSize = function transformSize(size) {
  var _size = _slicedToArray(size, 1),
    value = _size[0];
  if (Array.isArray(value)) {
    return value;
  }
  return size;
};
function AdUnit(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure,
    onClick = _ref.onClick,
    slotOnload = _ref.slotOnload,
    props = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    unitId = _useState2[0],
    setUnitId = _useState2[1];
  var cursorPointer = staticProps.cursorPointer,
    path = staticProps.path,
    _staticProps$responsi = staticProps.responsive,
    responsive = _staticProps$responsi === void 0 ? true : _staticProps$responsi,
    size = staticProps.size,
    touch = staticProps.touch,
    _staticProps$target = staticProps.target,
    target = _staticProps$target === void 0 ? [] : _staticProps$target;
  var _structure$cookies = structure.cookies;
  _structure$cookies = _structure$cookies === void 0 ? {} : _structure$cookies;
  var _structure$cookies$cl = _structure$cookies.clientId,
    clientId = _structure$cookies$cl === void 0 ? '' : _structure$cookies$cl;
  var _transformSize = transformSize(size),
    _transformSize2 = _slicedToArray(_transformSize, 2),
    unitWidth = _transformSize2[0],
    unitHeight = _transformSize2[1];
  var height = Math.ceil(unitHeight / unitWidth * 100);
  useEffect(function () {
    var _window = window,
      _window$googletag = _window.googletag,
      googletag = _window$googletag === void 0 ? {} : _window$googletag;
    var targetSlot;
    if (googletag.cmd) {
      googletag.cmd.push(function addSlot() {
        targetSlot = googletag == null ? void 0 : googletag.defineSlot(path, size);
        setUnitId(targetSlot.getSlotElementId());
        targetSlot.addService(googletag.pubads());

        targetSlot.setForceSafeFrame(!touch);
        targetSlot.setTargeting('GA_USER_ID', clientId);
        target.forEach(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
            targetKey = _ref3[0],
            targetValue = _ref3[1];
          if (targetKey && targetValue) {
            targetSlot.setTargeting(targetKey, targetValue);
          }
        });
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
        googletag.display(unitId);
        googletag.pubads().refresh([targetSlot]);
        googletag.pubads().addEventListener('slotRenderEnded', slotOnload);
      });
    }
    return function () {
      if (googletag.cmd) {
        googletag.cmd.push(function removeSlot() {
          googletag.destroySlots([targetSlot]);
        });
      }
      setUnitId();
    };
  }, [path, size]);

  var handleClick = function handleClick(event) {
    var _iframe$contentWindow, _iframe$contentWindow2, _iframe$contentWindow3, _iframe$contentWindow4;
    onClick(event);
    var iframe = event.target.querySelector('iframe');
    return iframe == null ? void 0 : (_iframe$contentWindow = iframe.contentWindow) == null ? void 0 : (_iframe$contentWindow2 = _iframe$contentWindow.document) == null ? void 0 : (_iframe$contentWindow3 = _iframe$contentWindow2.body) == null ? void 0 : (_iframe$contentWindow4 = _iframe$contentWindow3.querySelector('a')) == null ? void 0 : _iframe$contentWindow4.click();
  };
  var isHeigthFixed = responsive ? "".concat(height, "%") : "".concat(unitHeight, "px");
  return React.createElement(Box, _extends({
    "data-testid": "adunit",
    cursorPointer: cursorPointer,
    touch: touch,
    width: "100%",
    height: "0",
    paddingTop: isHeigthFixed,
    overflow: "hidden",
    onClick: handleClick
  }, props, {
    id: unitId
  }));
}
AdUnit.defaultProps = {
  onClick: function onClick() {},
  slotOnload: function slotOnload() {},
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? AdUnit.propTypes = {
  onClick: func,
  slotOnload: func,
  "static": shape({
    cursorPointer: bool,
    path: string.isRequired,
    responsive: bool,
    size: arrayOf(oneOfType([number, arrayOf(number)])),
    target: arrayOf(arrayOf(oneOfType([number, string]))),
    touch: bool
  }),
  structure: shape({})
} : void 0;
AdUnit.ssr = true;
export default withLayoutProps(AdUnit);